.profile {
    padding: $spacing-double 0 $spacing 0;

    &__separator {
        background-color: $gray1;
        text-align: center;
        font-size: 17px !important;
        padding: 5px;
        font-weight: 100;
        color: #8e8d8d;
        font-style: oblique;

        .edit-profile-button {
            display: inline-block;
            opacity: 0;
            position: absolute;
            right: 10px;
            transform: translateX(-100%);
            transition: opacity 0.5s;
        }
    }

    .profile-info {
        position: relative;
        margin-bottom: $spacing;

        &__name {
            margin: 0 0 0 0;
            padding: 0;
            font-size: 25px;
            font-weight: $font-bold;
            text-align: center;
        }

        &__date {
            margin: 0 0 $spacing-half 0;
            padding: 0;
            font-size: 18px;
            font-style: italic;
            color: #999;
            text-align: center;
        }

        &__details {
            width: 100%;
        }

        &__avatar {
            position: absolute;
            top: 0;
            left: 5%;
            border-radius: 100%;
            overflow: hidden;

            img {
                border-radius: 100%;
                border: 5px solid $gray2;
                width: 140px;
                height: 140px;
                object-fit: cover;
            }
        }

        &__avatar:hover {
            .profile-info__avatar-overlay {
                opacity: 1;
            }
        }

        &__avatar-overlay {
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.3);
            transition: opacity 0.5s;

            a {
                display: inline-block;
                font-size: 25px;
                position: absolute;
                top: calc(100% - 10px);
                left: 50%;
                color: $gray2;
                transform: translateX(-50%) translateY(-100%);
            }

            a:hover {
                color: #fff;
            }

            input {
                width: 0 !important;
                position: absolute;
                left: -1000px;
            }
        }

        .info-item-bio {
            margin: 0 0 $spacing-half 0;
            padding: 0;
            font-size: 16px;
            display: flex;
            justify-content: flex-end;


            .info-value-bio {
                width: 60%;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                text-align: left;
            }

            h4 {
                width: 45%;
                text-align: right;
                color: #999;
            }
        }

        .info-item {
            margin: 0 0 $spacing-half 0;
            padding: 0;
            font-size: 16px;
            display: flex;
            justify-content: space-between;

            .info-value {
                width: 50%;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
            }

            h4 {
                width: 45%;
                text-align: right;
                color: #999;
            }
        }

        .sprite-container {
            width: 37.5px;
            height: 23px;
        }

        .sprite {
            transform: scale(0.5, 0.5) translate(-50%, -50%);
        }

        &:hover {
            .edit-profile-button {
                opacity: 1;
            }
        }

        @include screen-md() {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;

            &__avatar {
                position: relative !important;
                left: 0;
                margin-bottom: $spacing;
            }
        }
    }

    .profile-events {
        &__list {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }
    }

    .profile-subjects2 {
        display: inline-flex;
        min-width: 55px;
        height: 20px;
        border-radius: 5px;
        box-shadow: 1px 2px 2px 2px #efeeee;
        padding: 3px 10px 1px 7px;
        margin: 3px 20px 10px 2px;
        contain: layout;
        box-sizing: border-box;
        text-align: left;
        cursor: pointer;

        .profile-languages2 {
            font-size: 11px;
            margin-right: 5px;
        }

        .ding-section-votes {
            position: absolute;
            top: -27%;
            width: 25px;
            height: 25px;
            border-radius: 100%;
            background-color: #f06f;
            padding: 5px 1px 4px 1px;
            font-size: 11px;
            color: white;
            text-align: center;
        }

        .profile-votes2 {
            width: 30%;
        }
    }


}

.edit-section {
    display: flex;
    justify-content: flex-end;
    height: 30px;

    .edit-button {
        width: 14%;
        height: 100%;
        background-color: #f06;
        border-radius: 12px;
        padding: 2px 0px 2px 0px;
        color: white;
        cursor: pointer;
        font-weight: bolder;
        font-size: 18px;
        text-align: center;
    }

    .edit-button p {
        margin: 0 auto;
    }
}


.edit-user {
    width: 400px;
    max-width: 100%;
    border-radius: 0;
    border: 1px solid #636363;
    padding: $spacing-half;
    text-align: center;
    font-family: Source sans pro;

    &__close {
        position: absolute;
        top: 0;
        right: 5px;
        font-size: 20px;
    }

    &__content {
        margin: $spacing 0;
    }

    &__add {
        display: block;
        text-align: left;
        margin-bottom: $spacing;
        color: #999;

        .fa {
            display: inline-block;
            margin-right: $spacing-half;
        }
    }

    .rich-autocomplete-list {
        max-height: 100px !important;

        .sprite-container {
            width: 37.5px;
            height: 23px;
            margin-right: $spacing-half;
        }

        .sprite {
            transform: scale(0.5, 0.5) translate(-50%, -50%);
        }

        .flex {
            display: flex;
            align-items: center;
        }
    }

    h4 {
        color: #999;
        margin: 0;
        padding: 0 0 0 $spacing-half;
        font-size: 16px;
        text-align: left;
    }

    h2 {
        font-size: 20px;
        text-align: center;
        font-weight: $font-bold;
        margin-bottom: $spacing;
    }
}

svg.add-plus {
    width: 23px;
    height: 23px;
}

.table {
    margin-bottom: 0px !important;
    font-size: 19px;
    font-family: Source sans pro;
}

.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
    padding: 4px;
}

.table-quotas {
    
    font-family: Source sans pro;
    background-color: #defffc;
    border-radius: 12px;

    td {
        vertical-align: middle !important;
        border-top: 0 !important;
    }

     .col-turquoise {
        background: rgb(0,255,226);
        background: linear-gradient(90deg, #04c4d8 20%, #23e2cc 100%);
        text-align: center;
        color: #fff;
        vertical-align: middle;
        max-width: 90px;
     }
    
    .td-result {
        padding-left: 25px;
        color: #4db099;
    }

    .td-result span {
        color: #414142;
        height: 100%;
        display: inline-block;
        vertical-align: middle;
    }

    span .iconPlus {
        vertical-align: middle;
        height: 80%;
        display: inline-block;
    }

    .th-style {
        font-weight: initial;
        text-align: center;
    }

    .add-more-swaps {
        border: 3px solid #008080;
        border-radius: 13px;
        padding: 0px 8px 0px 8px;
        color: #008080;
        background: white;
        cursor: pointer;
    }

    .svg-votes {
        padding-top: 3px;
        padding-left: 5px;
        align-self: center;
    }

    .svg-filters {
        display: flex;
    }

    .votes-style {
        display: inline-flex;
    }

    .votes-style p {
        margin: 0;
    }

    /* SVG for table */

    .svg-votes svg {
        width: 19px;
        height: 19px;
    }

    svg path {
        stroke: #fff;
    }

    svg path.talk1 {
        fill: #fff;
    }

    svg path.meet {
        stroke: #fff;
    }

    svg path.exchange {
        fill: #fff;
    }

    svg .a {
        stroke: #fff;
    } 

    svg path.browse {
        stroke: #fff;
    }

    svg rect.browse {
        stroke: #fff;
    }

    .svg-filters svg {
        width: 100%;
    }

    /* i ELEMENTS */

    i {
        color: #4db099;
    }
}

/* Styles for new elements */

.icon-add-lang {
    width: 18px !important;
    height: 28px !important;
}

.add-profile-photo {
    cursor: pointer;
}

.add-profile-photo svg {
    width: 100%;
    height: 100px;
}

.add-new-image-user {
    display: flex;
    font-size: 15px;
}

.status-account {
    width: 36%;
    align-self: end;
}

.user-icon {
    font-size: 95px;
    text-align: center;
    width: 100%;
}

.change-user-image {
    background-color: #f06;
    border-radius: 14px;
    padding: 0px 9px 0px 9px;
    color: #fff;
    font-weight: bold;
}

.city-name {
    background-color: #fff;
    max-height: 26px;
    color: #f06;
    border-radius: 12px;
    padding: 0px 7px 0px 7px;
    margin-right: 5px;
    border: 2px solid #f06;
    font-weight: bold;
}

.add-city {
    color: #fff;
    width: 23px;
    text-align: center;
    height: 23px;
    border-radius: 14px;
    padding: 1px 0px 0px 1px;
    cursor: pointer;
    display: flex;
}

.cities-container {
    display: flex;
    width: 69%;
    padding-left: 10px;
}

.user-votes-container {
    width: 50px;
    height: 50px;
    background-color: #f06;
    border-radius: 26px;
    margin-left: 10px;
    display: flex;

    p {
        align-self: center;
        margin: 0;
        text-align: center;
        width: 100%;
        color: #fff;
        font-weight: bold;
    }
}


/* PROGRESS BAR CSS */

/* #04c4d8 and #23e2cc */

.progress-bar {
    display: flex;
    width: 100%;
    height: 23px;
    background: rgb(0,255,226);
    background: linear-gradient(90deg, #04c4d8 20%, #23e2cc 100%);
    border-radius: 14px;
}

.progress-data {
    height: 100%;
    width: 33%;

    p {
        color: #fff;
        font-weight: bold;
    }
}

.progress-percent {
    display: flex;
    border-radius: 50px;
    height: 60px;
    width: 60px;
    position: absolute;
    right: -10px;
    top: -17px;
    background-color: rgba(8,207,168,1);

    p {
        margin: 0;
        color: #fff;
        font-weight: bold;
        align-self: center;
        text-align: center;
        width: 100%;
    }
}

/* EXP Stickers */

.user-exp-dings {
    display: flex;
}

.sticker-info-green {
    display: flex;
    width: 280px;
    background: linear-gradient(90deg, #00ffe2 20%, #08cfa8 100%);
    border-radius: 27px;
    height: 85px;
    margin: 4px;

    .icon {
        align-self: center;
        width: 16%;
        padding-left: 5px;
    }

    .exp-text {
        font-size: 12px;
        width: 68%;
        color: #fff;
    }

    .exp-text h4 {
        color: #fff;
        font-weight: bold;
    }

    .user-check {
        align-self: center;
        margin-left: 2px;
        font-size: 38px;
        color: #fff;
    }

    svg .a {
        stroke: #fff;
    }
}

.sticker-info-black {
    display: flex;
    width: 280px;
    background: #585859;
    border-radius: 27px;
    height: 85px;
    margin: 4px;

    .icon {
        align-self: center;
        width: 16%;
        padding-left: 5px;
    }

    .exp-text {
        font-size: 12px;
        width: 68%;
        color: #fff;
    }

    .exp-text h4 {
        color: #fff;
        font-weight: bold;
    }

    .user-check {
        align-self: center;
        margin-left: 2px;
        font-size: 38px;
        color: #fff;
    }

    svg .a {
        stroke: #fff;
    }
}

.accept-profile-changes {
    font-family: Source sans pro;
    max-width: 365px;
    padding: 30px;

    h4 {
        font-size: 2.2rem;
        margin-bottom: 18px;
        text-align: center;
        line-height: revert;
        font-weight: 400;
    }

    p {
        text-align: center;
        font-size: 20px;
        margin-bottom: 17px;
    }

}

.tick {
    position: relative;
    width: 23px;
    height: 16px;
}


@include screen-sm() {

    .add-more-swaps {
        display: inline-block;
    }

}

@include screen-xs() {

    .profile {
        

        
        /* USER INFO */

        .edit-section {
            justify-content: center;
        }

        .edit-section .edit-button {
            width: 25%;
        }

        .profile-info {

            .info-item h4 {
                width: 19%;
                text-align: center;
            }

            .info-item-bio {
                justify-content: center;
                margin: 20px 0px 20px 0px;
            }
        }

        .add-city {
            margin: 0px 18px 0px 0px;
        }


        /* PROGRESS BAR */

        .progress-bar {
            width: 90%;
            margin: 0px 9px;
        }

        .user-progress-bar {
            overflow: unset;
        }

        .progress-data {
            width: 29%;
        }


         /* ACTIONS XP */

        .sticker-info-black {

            .icon {
                align-self: center;
                width: 23%;
                padding-left: 2px;
            }

            .exp-text {
                font-size: 0.75714rem;
                width: 54%;
            }

            .exp-text h4 {
                font-size: 13px;
                margin-bottom: 0px;
                margin-top: 4px;
            }

            .user-check {
                margin-left: 4px;
            } 
        }

        .sticker-info-green {

            .icon {
                align-self: center;
                width: 23%;
                padding-left: 2px;
            }

            .exp-text {
                font-size: 0.75714rem;
                width: 54%;
            }

            .exp-text h4 {
                font-size: 13px;
                margin-bottom: 0px;
                margin-top: 4px;
            }

            .user-check {
                margin-left: 4px;
            } 
        }


        /* CHANGE USER IMAGE SECTION */

        .add-new-image-user {
            display: inline-block;
            width: 100%;
        }

        .status-account {
            text-align: center;
            width: 100%;
        }

        .add-profile-photo {
            width: 50%;
            margin: 0 auto;
        }

        .change-user-image {
            text-align: center;
        }


        /* TABLE */

        .table > thead > tr > th {
            vertical-align: middle;
        }

        .table > tbody > tr > td {
            vertical-align: middle;
            padding-left: 8px;
            text-align: center;
        }

        .add-more-swaps {
            display: inline-block;
        }

        .table {
            font-size: 13px;
            font-family: Source sans pro;

            th {
                width: 23%;
            }

            .table-quotas {
                .svg-votes {
                    padding-top: 0px;
                    padding-left: 5px;
                }

                .votes-style {
                    margin-top: 7px;
                }

                .td-result {
                    padding-left: 8px;
                    text-align: center;
                }

                .td-result span {
                    display: flow-root;
                }
            }

        }

    }

}