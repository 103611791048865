.cities-popup {
    max-width: 870px;
    border-radius: 10px !important;

    &__close {
        position: absolute;
        top: 0;
        right: 5px;
        font-size: 20px;
    }

    &__title {
        width: 100%;
        color: $color-brand-2;
        font-weight: $font-bold;
        font-size: 25px;
        text-align: center;
    }

    &__content {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
    }

    @include screen-sm() {
        padding: 35px 10px !important;
    }
}

.continent-column {
    width: calc(25% - 40px);
    margin-right: $spacing-double;

    &__title {
        padding-bottom: $spacing-half;
        border-bottom: 3px solid $color-brand-2;
        color: $color-font-heading;
        font-weight: $font-bold;
        text-align: center;
    }

    &__list {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    &:last-child {
        margin-right: 0;
    }

    @include screen-sm() {
        width: 50%;
        margin-right: 0px;
        margin-bottom: $spacing;

        &:nth-child(even) {
            margin-right: 0;
        }
    }
}

.continent-city {
    height: 27px;
    width: fit-content;
    border-radius: 13px;
    border: 2px solid #e91e63;
    box-shadow: 1px 2px 2px 2px #efeeee;
    padding: 0px;
    margin: 15px auto;
    background-color: #fff;
    color: #f06f;
    box-sizing: border-box;
    text-align: center;
    cursor: pointer;
    font-weight: 300;
    font-family: Be Vietnam Pro;

    /* position: relative;
    width: 100%;
    margin: 4px 0;
    padding: 9px;
    text-align: center;
    cursor: pointer;
    transition: all 0.25s ease;
    background-color: transparent;
    background-size: 100% 200%;
    background-image: linear-gradient(to bottom, $color-modal-bg 50%, $color-brand-2 50%);
    font-family: Source Sans Pro; */

    .result-city {
        color: #f06;
        height: 100%;
        font-size: 18px;
        font-weight: bolder;
        display: inherit;
        padding: 0px 10px;
        display: flex;
        contain: content;

        .result-delete {
            height: 0%;
            overflow: hidden;
            color: #f06f;
            font-weight: bolder;
            transition: opacity 0.5s ease-out;
            opacity: 0;
            width: 0px;

            p {
                line-height: 15px;
            }
        }

        a {
            color: #f06;
            font-weight: bolder;
            text-decoration: none;
        }
    }

    .result-city:hover {

        .result-delete {
            height: 115%;
            opacity: 1;
            display: block;
            padding-top: 5px;
            width: 50px;
        }
    }

    .result {
        transition: display 0.8s linear 0.2s;
    }

    &:hover, &.active {
        & a {
            color: #f06;
        }
    }

    & a {
        text-decoration: none;
    }

    &__remove {
        position: absolute;
        right: $spacing-half;
        top: 35%;
        color: white;
        opacity: 0;
    }
}

.city-adder {
    position: relative;
    margin: $spacing-half 0 30px 0;
    height: $spacing;

    & * {
        transition: all 0.2s ease;
    }

    &__overlay {
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        font-size: 20px;
        cursor: pointer;

        & * {
            pointer-events: none;
        }
    }

    .rich-autocomplete {
        ul {
            opacity: 0;
            overflow-x: hidden;
            max-height: 100px !important;

            li {
                height: auto !important;
                margin: 5px 0;
            }

            li:first-child {
                margin: 0 0 5px 0;
            }

            li:last-child {
                margin: 5px 0 0 0;
            }
        }

        p {
            margin: 0;
            padding: 0;
            line-height: 15px !important;
            font-size: 15px !important;
            word-wrap: break-word !important;
        }
    }

    &__select {
        opacity: 0;
        padding: $spacing-half $spacing;
        width: 100%;
        text-align-last: center;
    }

    &__input {
        margin: 0;
        padding: $spacing-half $spacing;
        width: 100%;
        opacity: 0;
        text-align: center;
    }

    & button {
        opacity: 0;
        width: 100%;
        display: none;
    }

    &--open {
        height: auto;

        & .city-adder__overlay {
            opacity: 0;
            z-index: -1;
        }

        & .city-adder__select {
            opacity: 1;
        }

        & .city-adder__input, button:not([disabled]) {
            opacity: 1;
        }

        & button {
            display: inherit;
        }

        & .rich-autocomplete ul {
            opacity: 1;
        }
    }
}
