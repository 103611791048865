html.remodal-is-locked body, body.remodal-is-locked body {
    height: 100%;
    overflow-y: hidden;
    overflow-x: hidden;
}

.landing {
    height: 100%;
    display: flex;
    overflow: hidden;
    flex-direction: column;
    justify-content: space-between;
    font-family: 'Be Vietnam Pro';
    background: #f6f6f6;
    margin-top: 0px !important;

    .page-content {
        margin-top: 0px !important;
        padding-top: 70px;
        background: #f6f6f6 !important;
    }

    &__text {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: calc(100% - 15vw);

        h1 {
            font-size: 30px;
            font-weight: $font-bold;
            font-family: 'Be Vietnam Pro';
        }

        img, picture {
            margin: $spacing-double auto 0 auto;
            width: 300px;
            max-width: 100%;
        }
    }

    &__images {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;


        img, picture {
            width: calc(100% / 5);
            height: 20vw;
            margin: 0;
            padding: 0;
        }
    }

    .form-group {
        width: 200px;
        margin: $spacing auto 0 auto;
    }

    @include screen-lg() {
        &__text {
            height: calc(100% - 25vw);

            h1 {
                font-size: 27px;
            }

            h3 {
                font-size: 22px;
            }

            img {
                margin-top: 20px;
            }
        }

        &__images {
            img, picture {
                width: calc(100% / 4);
                height: 25vw;
            }
        }
    }

    @include screen-md() {
        &__text {
            height: calc(100% - 33.3vw);
        }

        &__images {
            img, picture {
                width: calc(100% / 3);
                height: 33.3vw;
            }
        }
    }

    @include screen-sm() {
        height: calc(100% - 167px);
        margin-top: 70px !important;

        &__text {
            height: calc(100% - 50vw);

            h1 {
                font-size: 25px;
            }

            h3 {
                font-size: 20px;
            }

            img {
                margin-top: 15px;
            }
        }

        &__images {
            img, picture {
                width: calc(100% / 2);
                height: 50vw;
            }
        }
    }

    @include screen-xs() {
        height: auto;
        margin-top: 70px !important;

        &__text {
            h1 {
                font-size: 20px;
            }

            h3 {
                font-size: 16px;
            }
        }

        &__images {
            margin-top: $spacing-third;
        }
    }
}

.classic-new-page {
    font-family: Source sans pro;
    max-width: 480px;
    min-width: 360px;
    padding: 35px;
    text-align: center;
    contain: content;
    border-radius: 12px !important;
    contain: inherit;

    .new-page-close {
        position: absolute;
        top: 0;
        right: 5px;
        font-size: 20px;
    }

    .remodal-buttons {
        width: 100%;
        height: 30px;
        position: relative;
        display: flex;
    }

    h2 {
        font-size: 24px;
        font-weight: 700;
        margin: 0px 0px 8px 0px;
    }

    p {
        font-size: 18px;
        font-weight: 300;
        font-family: 'Be Vietnam Pro';
        margin-bottom: 25px;
    }

    .separator {
        width: 100%;
        height: 1px;
        border-bottom: 1px solid #c4c1c1;
        margin-top: 20px;
        margin-bottom: 23px;
    }

    .btn {
        font-family: Be Vietnam Pro;
        font-weight: 300;
        font-size: 18px;
        text-transform: initial;
    }

    .input-group {
        display: flex;
        align-items: center;

        input {
            margin: 4px 0px;
            display: flex;
            align-self: center;
        }

        span {
            margin-left: 6px;
        }
    }

}

.verified-user {
    max-width: 470px;
    max-height: 300px;
}

.text-box {
    display: inline-table;
    text-align: center;

    h2 {
        font-family: Be Vietnam Pro;
        font-weight: 600;
        font-size: 22px;
        margin-top: 0px;
        margin-bottom: 17px;
    }

    p {
        font-size: 1.3rem;
        font-family: Be Vietnam Pro;
        margin-bottom: 22px;
    }

    .btn {
        font-family: Be Vietnam Pro;
        font-weight: 300;
    }
}