.header {
    position: fixed;
    width: 100%;
    margin: 0;
    padding: 0;
    background-color: #f6f6f6;
    transition: 0.5s;
    z-index: 4;


    &__content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 9px 23px 4px 30px;

        .left-side {
            display: flex;
            justify-content: flex-start;
        }

        .right-side {
            height: 32px;
            display: flex;
            justify-content: end;
        }

        .middle {
            width: 100px;
            margin: 0 $spacing;
            transition: width 0.5s;
            text-align: center;

            img {
                width: 50px;
                transition: width 0.5s;
            }
        }


        @include screen-sm() {
            div.row {
                margin: 0 !important;
            }

        }

        @include screen-xs() {
            margin: 5px 21px 7px 21px;

            div.row {
                margin: 0 !important;
            }
        }
    }


    .top-link {
        font-size: 1.2rem;
        font-weight: 400;
        font-family: Be Vietnam Pro;
        align-self: center;
        color: #626262;
        padding-top: 0px;
        border-bottom-width: 5px;
        border-bottom-style: solid;
        text-decoration: none;
        background-size: 100% 200%;
        transition: 0.5s;
        height: 30px;
        width: 42px;
        display: flex;
        justify-content: center;
        align-content: center;
        text-align: center;
        align-items: center;

        a {
            text-decoration: none;
        }


        &.-blue-bottom-border {
            border-bottom-color: #ebebeb;
            background-image: linear-gradient(to bottom, #f6f6f6 50%, #ebebeb 50%);

            &:hover, &:active, &:focus, &.active {
                color: #fff;
                border-bottom-color: darken(#f6f6f6, 30%);
                background-position: 0 100%;
            }
        }

        &.-orange-bottom-border {
            border-bottom-color: #ebebeb;
            background-image: linear-gradient(to bottom, #f6f6f6 50%, #ebebeb 50%);

            &:hover, &:active, &:focus, &.active {
                color: #fff;
                border-bottom-color: darken(#f6f6f6, 30%);
                background-position: 0 100%;
            }
        }

        &.-purple-bottom-border {
            border-bottom-color: #ebebeb;
            background-image: linear-gradient(to bottom, #f6f6f6 50%, #ebebeb 50%);

            &:hover, &:active, &:focus, &.active {
                color: #fff;
                border-bottom-color: darken(#f6f6f6, 30%);
                background-position: 0 100%;
            }
        }
    }
    .top-link:hover {
        a {
            color: #fff;
        }
    }

    .top-link-admin {
        font-size: 1.2rem;
        font-weight: 400;
        font-family: Be Vietnam Pro;
        align-self: center;
        color: #626262;
        margin: 0 auto;
        padding-top: 0px;
        border-bottom-width: 5px;
        border-bottom-style: solid;
        text-decoration: none;
        background-size: 100% 200%;
        transition: 0.5s;
        height: 30px;
        width: 52px;
        display: flex;
        justify-content: center;
        align-content: center;
        text-align: center;
        align-items: center;

        a {
            text-decoration: none;
        }


        &.-blue-bottom-border {
            border-bottom-color: #ebebeb;
            background-image: linear-gradient(to bottom, #f6f6f6 50%, #ebebeb 50%);

            &:hover, &:active, &:focus, &.active {
                color: #fff;
                border-bottom-color: darken(#f6f6f6, 30%);
                background-position: 0 100%;
            }
        }

        &.-orange-bottom-border {
            border-bottom-color: #ebebeb;
            background-image: linear-gradient(to bottom, #f6f6f6 50%, #ebebeb 50%);

            &:hover, &:active, &:focus, &.active {
                color: #fff;
                border-bottom-color: darken(#f6f6f6, 30%);
                background-position: 0 100%;
            }
        }

        &.-purple-bottom-border {
            border-bottom-color: #ebebeb;
            background-image: linear-gradient(to bottom, #f6f6f6 50%, #ebebeb 50%);

            &:hover, &:active, &:focus, &.active {
                color: #fff;
                border-bottom-color: darken(#f6f6f6, 30%);
                background-position: 0 100%;
            }
        }
    }
    .top-link-admin:hover {
        a {
            color: #fff;
        }
    }

    .top-link2 {
        margin: 0px;
        padding: 0px;
        border-bottom-width: 5px;
        border-bottom-style: solid;
        text-decoration: none;
        background-size: 100% 200%;
        transition: 0.5s;
        height: 30px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-content: center;
        text-align: center;
        align-items: center;
        width: fit-content;

        .svg-container {
            height: 100%;

            svg {
                height: 100%;
                width: 17px;
                stroke: #4c4c4c;
            }
        }

        h2 {
            font-size: 1.2rem;
            font-weight: 400;
            margin: 0px;
            font-family: Be Vietnam Pro;
            align-self: center;
            color: #626262;
            padding-right: 1px;

            &:hover, &:active, &:focus, &.active {
                color: #fff;
                border-bottom-color: darken(#f6f6f6, 30%);
                background-position: 0 100%;
            }

            &:hover, &:active, &:focus, &.active {
                color: #fff;
                border-bottom-color: darken(#f6f6f6, 30%);
                background-position: 0 100%;
            }

            &:hover, &:active, &:focus, &.active {
                color: #fff;
                border-bottom-color: darken(#f6f6f6, 30%);
                background-position: 0 100%;
            }

        }


        &.-blue-bottom-border {
            border-bottom-color: #ebebeb;
            background-image: linear-gradient(to bottom, #f6f6f6 50%, #ebebeb 50%);

            &:hover, &:active, &:focus, &.active {
                border-bottom-color: darken(#f6f6f6, 20%);
                background-position: 0 100%;
            }
        }

        &.-orange-bottom-border {
            border-bottom-color: #ebebeb;
            background-image: linear-gradient(to bottom, #f6f6f6 50%, #ebebeb 50%);

            &:hover, &:active, &:focus, &.active {
                color: #fff;
                border-bottom-color: darken(#f6f6f6, 20%);
                background-position: 0 100%;
            }
        }

        &.-purple-bottom-border {
            border-bottom-color: #ebebeb;
            background-image: linear-gradient(to bottom, #f6f6f6 50%, #ebebeb 50%);

            &:hover, &:active, &:focus, &.active {
                color: #fff;
                border-bottom-color: darken(#f6f6f6, 20%);
                background-position: 0 100%;
            }
        }
    }

    &.is-scrolled {
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.75);
        z-index: $zindex-header;

        .middle {
            width: 100px;

            img {
                width: 40px;
            }
        }
    }

    .top-link2:hover {

        .header-svg1 {
            stroke: #fff !important;
        }
        .header-svg2 {
            fill: #fff !important;
        }
        h2 {
            color: #fff;
        }

    }

    @include screen-sm() {
        &__content {
            align-items: flex-start;
            justify-content: center;
            flex-wrap: wrap;
        }

        .middle {
            order: 2;
            text-align: center;
            margin-bottom: $spacing;
        }

        .left-side {
            order: 1;
            margin: 0px 0px 10px 0px;
            text-align: center;
            display: block;
        }

        .right-side {
            order: 3;
            margin: 0px;
            text-align: center;
        }

        .dissapear {
            display: none;
        }
        .top-link {
            margin: 0 0;
            display: flex;
            justify-content: left;
            align-content: flex-start;
            text-align: center;
            align-items: center;
        }
    }

    @include screen-xs() {
        .top-link {
            padding: 0;
        }
        .top-link-admin {
            margin: 0 auto;
            padding: 0;
        }

        .container {
            width: 100%;
            padding: 0;
        }

        .middle {
            margin: 0 auto;
        }

        .dissapear {
            display: none;
        }

        .middle {
            order: 2;
            width: 50px;
            text-align: center;
            margin-bottom: 10px;
        }

        .left-side {
            order: 1;
            margin: 0px 0px 10px 0px;
            text-align: center;
            display: block;
        }

    }

    .dropdown-content {
        display: none;
        position: absolute;
        background-color: #f1f1f1;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 20;

        a {
            color: #626262;
            padding: 12px 16px;
            text-decoration: none;
            display: block;
            font-weight: 400;
            font-family: Be Vietnam Pro;
        }

        a:hover {
            color: #626262 !important;
        }
    }

    .dropbtn {
        border: none;
        background: transparent;
        font-size: 1.2rem;
        font-weight: 400;
        font-family: Be Vietnam Pro;
        color: #626262;
        text-align: center;
    }
    .dropbtn:hover {color: #fff}

    .dropdown-content a:hover {background-color: #ddd; color: #626262 !important}

    .dropdown-cities:hover .dropdown-content {display: block;}

}

body.is-loading header.is-scrolled {
    position: absolute;
}
