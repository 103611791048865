.menu-popup {
    width: $media-sm;
    max-width: 100%;
    border-radius: 4px;
    border: 1px solid #636363;
    padding: $spacing-half;

    &__close {
        position: absolute;
        top: 0;
        right: 5px;
        font-size: 20px;
    }

    &__content {
        margin: $spacing 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    &__left {
        width: 50%;
    }

    &__right {
        width: 50%;
        border-left: 3px solid $gray2;
    }

    ul.menu {
        width: auto;
        list-style: none;
        padding: 0;
        margin: 0;
        text-align: center;

        li {
            margin: 0;
            padding: 0;
            font-family: Be Vietnam Pro;
        }

        a {
            display: inline-block;
            padding: 10px 0;
            width: 100px;
            border-bottom: 2px solid $gray2;
            text-decoration: none;
            font-weight: $font-bold;
            transition: color 0.25s;
            font-family: Be Vietnam Pro;

            &:hover, &:focus, &:active, &.active {
                color: $color-brand-1;
            }
        }

        li:last-child > a {
            border: 0;
        }
    }

    @include screen-sm() {
        &__content {
            flex-direction: column;
        }

        &__left {
            width: 100%;
            border: 0;
        }

        &__right {
            width: 100%;
            border-left: 0;
            border-top: 3px solid $gray2;
        }
    }
}
