.contact {
    padding: $spacing 0;
    text-align: center;

    .container {
        max-width: 950px !important;
        padding: 0px 0px !important;
    }

    h2 {
        padding: $spacing-half 0;
        font-weight: $font-bold;
    }

    .title {
        margin-bottom: $spacing-double;
        font-size: $font-size-base*5/4;
        text-align: center;
    }

    .modal-body {
        @include screen-sm() {
            .contact-details, .contact-form {
                /* width: 100% !important; */
                margin-top: $spacing-double;
            }
        }

        @include screen-xs() {
            .contact-details, .contact-form {
                margin-top: $spacing-double;
                float: left !important;
            }
        }
    }


    p {
        text-align: left;
        font-size: $font-size-base*5/4;
        margin-top: 0;
        margin-bottom: 0;
    }

    a {
        cursor: pointer;
        color: #1565c0;
    }

    ul {
        list-style-type: none;
        display: flex;
        flex-direction: column;
        margin-top: $spacing;
        margin-left: 0;
        text-align: left;
    }

    li {
        margin-top: $spacing-half;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin-bottom: 5px;
        border: 2px solid white;
        border-radius: 15px;
        -webkit-transition: border 1s;
        transition: border 1s;

        .name-email {
            margin-top: 5px;
        }
    }

    li:hover {
        cursor: pointer;
        background: $gray-for-back;
    }

    .selected {
        border: 2px solid $gray-for-back;
        background: $gray-for-back;
    }


    .details {
        margin: 0;
        font-size: $font-size-base;
    }

    .detailsname {
        font-weight: $font-bold;
        font-size: $font-size-base*5/4;
    }


    @include screen-sm() {
        .js-cityManagers {
            padding-left: 0px;
        }
    }
    @include screen-xs() {
        .js-cityManagers {
            padding-left: 0px;
        }
    }

}
